/*global $*/

$(() => {

  const body = document.getElementsByTagName('body')[0];
  switch (true) {
    case body.classList.contains('home'):
    initHome();
    break;
  }

  let data_type = $('html').attr('data-page')
  $('.nav-' + data_type).addClass('active')  
})

// トップページ初期化
function initHome() {

  // ページ内リンク取得
  const inPageAnchors = [];
  $('#GlobalMenu > .navigation-view > a[href*="#"]')
    .each((index, elm) => {
      const
        href = elm.href,
        hash = href.substr(href.indexOf('#') + 1),
        target = document.getElementById(hash);
      elm.hash = hash;
      inPageAnchors.push({
        elm: elm,
        target: target
      })
    })
    .on('click', e => {
      // ページ内リンクをクリックしたらページ内遷移アニメーション
      const
        target = document.getElementById(e.delegateTarget.hash.substr(1)),
        top = target.offsetTop;
      $('html, body').animate({ scrollTop: top - 120 }, 'slow', 'swing');
      return false;
    });

  $(window)
    .on('scroll resize', () => {
      // スクロール・リサイズしたらページ内リンクのハイライトを更新
      for (const anchor of inPageAnchors) {
        const innerrect = intersect(
          {y: window.scrollY, height: window.innerHeight},
          {y: anchor.target.offsetTop, height: anchor.target.offsetHeight}
        )
        if (innerrect.height > 0) {
          anchor.elm.classList.add('-in')
        } else {
          anchor.elm.classList.remove('-in')
        }
      }
    })
    .triggerHandler('resize')
}


function intersect(rect1, rect2) {
  const
    sy = Math.max(rect1.y, rect2.y),
    ey = Math.min(rect1.y + rect1.height, rect2.y + rect2.height),
    h = ey - sy;
  if (h > 0) {
    return { y: sy, height: h };
  }
  return { y: 0, height: 0 };
}

